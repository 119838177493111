function formatNums(el) {
    let num = +$(el).attr("data-num")
    $(el).html(num.toLocaleString())
}

$(".cart-row").each(function(i, el) {
    let priceSale = $(el).find("._price-sale")
    let price = $(el).find("._price")
    let priceSum = $(el).find("._price-sum")

    formatNums(price)
    formatNums(priceSale)
    formatNums(priceSum)


})

$(".cart__all input").change(function() {
    let checked = $(this).prop("checked")

    $(".cart-row__check input").each(function(i, el) {
        $(el).prop("checked", checked)
    })
})

$(".cart-row__check input").change(function() {
    let uncheckedItems = $(".cart-row__check input:not(:checked)").length

    if (uncheckedItems === 0) {
        $(".cart__all input").prop("checked", true)
    } else {
        $(".cart__all input").prop("checked", false)
    }
})

$(".promo-code").each(function (i,el) {
    let input = $(el).find("input")
    let clearBtn = $(el).find(".promo-code__clear")

    $(clearBtn).click(function () {
        $(input).val("")
        $(el).removeClass("_filled")
    })

    if($(input).val().length > 0){
        $(el).addClass("_filled")
    }else{
        $(el).removeClass("_filled")
    }

    $(input).on("input",function () {
        if($(this).val().length > 0){
            $(el).addClass("_filled")
        }else{
            $(el).removeClass("_filled")
        }
    })
})

$('[name="deliveryType"]').change(function () {
    let val = $(this).attr("data-value")
    if(val === "self"){
        $('[name="deliveryType2"]').prop("checked",false)
        $(".pay-type._view1").removeClass("_active")
        $(".pay-type._view2").addClass("_active")
    } else{
        $('[name="deliveryType2"][data-value="office"]').prop("checked",true)
        $(".pay-type._view2").removeClass("_active")
        $(".pay-type._view1").addClass("_active")
    }
})

$('[name="deliveryType2"]').change(function () {
    $('[name="deliveryType"][data-value="company"]').prop("checked",true)
    $(".pay-type._view2").removeClass("_active")
    $(".pay-type._view1").addClass("_active")
    let val = $(this).attr("data-value")
    if(val === "office"){
        $(this).parents(".delivery-field").addClass("_office-select")
    } else{
        $(this).parents(".delivery-field").removeClass("_office-select")
    }
})
