function Sliders() {
    let SlidersArray = []

    let sliders = document.querySelectorAll(".slider")
    sliders.forEach((slider,index) => {
        slider.setAttribute("data-slider-id",index)
        let isLoop = slider.classList.contains("_loop")
        let isNested = slider.classList.contains("_nested")

        SlidersArray[index] = new Swiper(`.slider[data-slider-id="${index}"] .swiper-container`, {
            slidesPerView: "auto",
            spaceBetween: 0,
            watchSlidesProgress: true,
            preventClicks :true,
            a11y: false,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            loop: isLoop,
            nested: isNested,
            navigation: {
                nextEl: `.slider[data-slider-id="${index}"] .swiper-button-next`,
                prevEl: `.slider[data-slider-id="${index}"] .swiper-button-prev`,
            },
            pagination: {
                el: `.slider[data-slider-id="${index}"] .swiper-pagination`,
                type: 'bullets',
                clickable: true,
            },
        })
    })
}

function gallery() {
    let SlidersArray = []

    let sliders = document.querySelectorAll(".gallery")
    sliders.forEach((slider,index) => {
        slider.setAttribute("data-slider-id",index)
        let gallery = {
            mySwiper: {},
            mySwiper2: {}
        }

        gallery.mySwiper = new Swiper(`.gallery[data-slider-id="${index}"] .mySwiper`, {
            spaceBetween: 0,
            slidesPerView: "auto",
            freeMode: true,
            watchSlidesProgress: true,
            direction: "vertical",
            breakpoints: {
                320: {
                    direction: "horizontal",
                },
                992: {
                    direction: "vertical",
                }
            }
        });
        gallery.mySwiper2 = new Swiper(`.gallery[data-slider-id="${index}"] .mySwiper2`, {
            spaceBetween: 0,
            direction: "vertical",
            // loop: true,
            navigation: {
                nextEl: `.gallery[data-slider-id="${index}"] .swiper-button-next`,
                prevEl: `.gallery[data-slider-id="${index}"] .swiper-button-prev`,
            },
            thumbs: {
                swiper:  gallery.mySwiper,
            },
            breakpoints: {
                320: {
                    direction: "horizontal",
                },
                992: {
                    direction: "vertical",
                }
            }
        });

        SlidersArray[index] = gallery
    })

}

function productSliders() {
    let SlidersArray = []

    let sliders = document.querySelectorAll(".product-slider")
    sliders.forEach((slider,index) => {
        slider.setAttribute("data-slider-id",index)

        SlidersArray[index] = new Swiper(`.product-slider[data-slider-id="${index}"] .product-slider__container`, {
            wrapperClass: "product-slider__wrapper",
            slideClass: "product-slider__slide",
            slidesPerView: "auto",
            spaceBetween: 0,
            watchSlidesProgress: true,
            preventClicks :true,
            a11y: false,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            navigation: {
                nextEl: `.product-slider[data-slider-id="${index}"] .product-slider__nav .swiper-button-next`,
                prevEl: `.product-slider[data-slider-id="${index}"] .product-slider__nav .swiper-button-prev`,
            },
            scrollbar: {
                el: `.product-slider[data-slider-id="${index}"] .swiper-scrollbar`,
                draggable: true,
                snapOnRelease: true,
                dragSize: 'auto',
            },
        })
    })
}

function productSliders2() {
    let SlidersArray = []

    let sliders = document.querySelectorAll(".product-slider2")
    sliders.forEach((slider,index) => {
        slider.setAttribute("data-slider-id",index)

        SlidersArray[index] = new Swiper(`.product-slider2[data-slider-id="${index}"] .product-slider__container`, {
            wrapperClass: "product-slider__wrapper",
            slideClass: "product-slider__slide",
            slidesPerView: "auto",
            spaceBetween: 0,
            watchSlidesProgress: true,
            preventClicks :true,
            a11y: false,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            grid: {
                fill: 'row',
                rows: 2,
            },
            navigation: {
                nextEl: `.product-slider2[data-slider-id="${index}"] .product-slider__nav .swiper-button-next`,
                prevEl: `.product-slider2[data-slider-id="${index}"] .product-slider__nav .swiper-button-prev`,
            },
            breakpoints: {
                120: {
                    slidesPerView: 2,
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
                576: {
                    slidesPerView: 3,
                    grid: {
                        fill: 'row',
                        rows: 1,
                    },
                },
                992: {
                    slidesPerView: "auto",
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
            },
        })
    })
}


Sliders()
gallery()
productSliders()
productSliders2()
