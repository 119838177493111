$(".news-nav__current").html($(".news-nav__link._active").html())

$(document).mouseup( function(e){
    var div = $( ".news-nav" );
    if ( !div.is(e.target)
        && div.has(e.target).length === 0 ) {
        if($(div).hasClass("_show")){
            $(div).removeClass("_show")
        }
    }
});

$(".news-nav__show").click(function () {
    $(this).parents(".news-nav").toggleClass("_show")
})
