$("._toggle-burger-menu").click(function () {
    $(".header").toggleClass("_open")
    $("body").toggleClass("fixed-body")
})

$(".catalog-btn").click(function () {
    $(".header").toggleClass("_open-catalog")
})

$(document).mouseup( function(e){
    let div = $( ".catalog-menu" );
    if ( !div.is(e.target)
        && div.has(e.target).length === 0 ) {
        if($(".header").hasClass("_open-catalog")){
            $(".header").removeClass("_open-catalog")
        }
    }
});

$(".catalog-menu>ul>li>a").click(function () {
    if(window.innerWidth > 992){
        return
    }
    $(this).siblings("ul").slideToggle()
    $(this).parent("li").toggleClass("_active")
})

$(".catalog-menu>ul>li>ul>li>ul:first-child").click(function () {
    if(window.innerWidth > 992){
        return
    }
    $(this).siblings("ul").slideToggle()
    $(this).parent("li").toggleClass("_active")
})

$("._show-search").click(function () {
    $(".header-search").addClass("_open")
    $(this).addClass("_active")
})

$("._search-field").on("input",function () {
    if($(this).val().length > 3){
        $(this).parents(".m-search").addClass("_show")
    }
})

$(document).mouseup( function(e){
    let div = $( ".header-search .search-field" );
    if ( !div.is(e.target)
        && div.has(e.target).length === 0 ) {
        if($(div).hasClass("_show")){
            $(div).removeClass("_show")
        }
    }
});

$(document).mouseup( function(e){
    let div = $( ".header-search" );
    if ( !div.is(e.target)
        && div.has(e.target).length === 0 ) {
        if($(div).hasClass("_open")){
            $(div).removeClass("_open")
            $("._show-search").removeClass("_active")
        }
    }
});
