$("._tab").click(function() {
    let parentBlock = $(this).parents("._tabs-parent")
    let tabId = $(this).attr("data-tab")
    $(parentBlock).find("._tab").removeClass("_active")
    $(this).addClass("_active")
    $(parentBlock).find(".tab-content").removeClass("_active")
    $(parentBlock).find(`.${tabId}`).addClass("_active")
})

$(document).on('click', '.rating-input__item', function(e) {
    let ratingItem = parseInt($(this).attr("data-rating-value"))
    $(this).parent(".rating-input").attr("data-total-rating", ratingItem)
    $(this).siblings(".rating-input__field").val(ratingItem)
})

$(".favourite-btn,.compare-btn").click(function () {
    $(this).toggleClass("_added")
})

$('.cartcalc').each(function (i,el) {
    let plus = $(el).find(".ccalc-plus")
    let minus = $(el).find(".ccalc-minus")
    let input = $(el).find("input")

    $(minus).click(function(e) {
        let a = $(input).val();
        if (a > 1) {
            let b = +a - 1;
            $(input).val(b);
            $(input).trigger('change');
        } else {
            $(input).val(a);
        }
        setMinusBtnClickability()
        e.stopImmediatePropagation()
    });

    $(plus).click(function(e) {
        let a = $(input).val();
        let b = +a + 1;
        $(input).val(b);
        $(input).trigger('change');
        setMinusBtnClickability(this)
        e.stopImmediatePropagation()
    });

    function setMinusBtnClickability() {
        let val = +$(input).val()
        if(val === 1){
            $(minus).addClass("_disabled")
        } else{
            $(minus).removeClass("_disabled")
        }
    }

    setMinusBtnClickability()
})

$(".pass-toggle").click(function () {
    if($(this).hasClass("_active")){
        $(this).removeClass("_active")
        $(this).siblings("input").attr("type","password")
    } else{
        $(this).addClass("_active")
        $(this).siblings("input").attr("type","text")
    }
})

$(".footer-nav__show").click(function () {
    if(window.innerWidth<=576){
        $(this).parents(".footer-nav").toggleClass("_active")
        $(this).siblings(".footer-nav__hidden").slideToggle()
    }
})
