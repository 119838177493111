$("._to-desc").click(function () {
    console.log(true)
    $(".product-detail ._tab[data-tab='_tab1']").click()
})

$("._add-cart-btn").click(function () {
    if($(this).hasClass("_active")){
        $(this).removeClass("_active")
    }else{
        $(this).addClass("_active")
        $(".product-alert").addClass("_active")
        setTimeout(()=>{
            $(".product-alert").removeClass("_active")
        },5000)
    }
})
